.Navbar a {
    font-size: 14px;
    font-weight: 700;
    color: black;
    flex-shrink: 0;
}

.Navbar a:hover, .Navbar a.active {
    text-decoration: underline;
    text-underline-offset: 5px;
}